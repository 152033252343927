import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '@styles/global'
import Section from '@components/Section'
import Button from '@components/Button'
import useSiteSettings from '@utils/useSiteSettings'
import Transition from '@components/Transition'
import { sentenceCase } from 'change-case'
import axios from 'axios'


const FeedbackForm = ({ className }) => {

	const [values, setValues] = useState({helpful: true})
	const [submitted, setSubmitted] = useState()

	const { sendFeedbackTo } = useSiteSettings()

	useEffect(() => {
		setValues(prevState => ({...prevState, page: window.location.href}))
	}, [])

	const submitFeedback = () => {
		const date = new Date
		axios.post('/api/send-to-big-query', {...values, helpful: values.helpful.toString(), timestamp: date.toISOString()})
			.then(res => {
				// console.log(res)
				setSubmitted(true)
			})
			.catch(err =>
				console.log(err)
			)
	}

	return (
		<Wrap className={className}>
			<Container>
				<Form>
					<Heading>Was this page helpful?</Heading>
					<Transition elementKey={submitted} duration={0.3}>
						{!submitted ?
							<>
								<SlideRadio>
									<Yes 
										onClick={() => setValues(prevState => ({...prevState, helpful: true}))}
										className='h5'	
									>yes</Yes>
									<SlideButton 
										onClick={() => setValues(prevState => ({...prevState, helpful: !values.helpful}))}
										css={css`
								background-color: ${ values.helpful ? 'var(--bteal)' : 'var(--teal)'};
							`}
									>
										<Circle
											css={css`
									left: ${values.helpful ? '6px' : 'calc(100% - (6px + 30px))'};
							`}/>
									</SlideButton>
									<No 
										onClick={() => setValues(prevState => ({...prevState, helpful: false}))}
										className='h5'
									>no</No>
								</SlideRadio>
								<input 
									type="checkbox" 
									hidden='true'
									checked={values.helpful} 
									onChange={event => 
										setValues(prevState => (
											{...prevState, helpful: !values.helpful}
										))
									}/>
								<Text>
						Please Tell us more
								</Text>
								<TextArea 
									className='sub'
									name='message' 
									id='message'
									placeholder="Type your feedback"
									onChange={e => setValues(prevState => ({...prevState, free_text: e.target.value}))}
								/>
								<Submit bgColor={'dteal'} onClick={()=> submitFeedback()}>
						Submit
								</Submit>
							</> :

							<>
								<p>Thank you for your feedback.</p>
							</>
						}
						
					</Transition>
					
				</Form>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxxl) 0;
	${mobile}{
		padding: 0;
	}
`
const Container = styled.div`
	grid-column: span 12;
	display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
	background-color: var(--peach20);
	min-height: 611px;
  ${mobile}{
    grid-column-gap: 14px;
    padding: 0 30px;
		margin: 0 -30px;
		min-height: 580px;
  }
`

const Form = styled.div`
	grid-column: 4/10;
	margin: var(--xxxl) 0;
	${mobile}{
		grid-column: span 12;
	}
`

const Heading = styled.h4`
	margin-bottom: var(--l);
	${mobile}{
		margin-bottom: var(--m);
	}
`

const SlideRadio = styled.div`
	display: grid;
	grid-template-columns: repeat(3, max-content);
	grid-column-gap: 20px;
	margin-bottom: var(--xl);
	${mobile}{
		margin-bottom: var(--m);
	}
`

const Yes = styled.button`
`

const No = styled.button`	
`

const SlideButton = styled.div`
	width: 80px;
	height: 42px;
	border-radius: 50px;
	box-sizing: border-box;
	position: relative;
	display: inline-block;
	transition: background-color 0.3s;
`

const Circle = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 100px;
	background-color: var(--white);
	position: absolute;
	top: 6px;
	transition: left 0.3s;
`

const Text = styled.h5`
	margin-bottom: var(--s);
`
const TextArea = styled.textarea`
	margin-bottom: var(--m);
	resize: none;
	display: block;
	width: 100%;
	height: 110px;
	padding: var(--xs);
	color: var(--dteal60);
	border: none;
	box-sizing: border-box;
	${mobile}{
		margin-bottom: var(--s)
	}
`
const Submit = styled(Button)`
	
`
FeedbackForm.propTypes = {
	className: PropTypes.string
}

export default FeedbackForm